<template>
  <div>
    <DataTable
      v-bind="{
        list: usersPage,
        pending,
        error,
        totalItems,
        pageSize,
        filters,
        header,
      }"
      @selected:filter="onFilterSelect"
    >
      <template #header:status="{ item: label }">
        <th class="text-center">
          {{ label }}
        </th>
      </template>

      <template #header:actions="{ item: label }">
        <th class="text-center xl:pr-11">
          {{ label }}
        </th>
      </template>

      <template #col:name="{ item: user }">
        <td class="font-bold pl-11">
          {{ user.lastName }}, {{ user.firstName }}
          {{ user.mainContactPerson ? '*' : '' }}
        </td>
      </template>

      <template #col:organisation="{ item: user }">
        <td class="truncate">
          {{ user.organisationName }}
        </td>
      </template>

      <template #col:role="{ item: user }">
        <td>
          {{ user.roleName }}
          <Pill
            v-if="user.userRoles.length > 1"
            class="text-white bg-blue w-10 ml-3"
          >
            +{{ user.userRoles.length - 1 }}
          </Pill>
        </td>
      </template>

      <template #col:status="{ item: user }">
        <td :class="isActive(user) ? 'text-primary' : 'text-darkGray'">
          <div
            class="mx-auto pl-3 w-[116px]"
            :class="canChangeStatus && 'cursor-pointer'"
            @click="canChangeStatus && openStatusDialog(user)"
          >
            <Icon
              class="inline-block w-5 xl:w-6"
              :name="isActive(user) ? 'check' : 'cancel'"
            />
            <span class="font-semibold align-middle ml-2">
              {{
                $t(`accountManagement.${isActive(user) ? 'active' : 'locked'}`)
              }}
            </span>
          </div>
        </td>
      </template>

      <template #col:actions="{ item: user }">
        <td class="text-center xl:pr-11">
          <Icon
            class="inline-block stroke-current cursor-pointer w-5 xl:w-6"
            name="edit"
            @click="openEditDialog(user)"
          />
          <Icon
            v-if="canChangeStatus"
            class="
              inline-block
              stroke-current
              cursor-pointer
              w-5
              ml-4
              xl:w-6 xl:ml-7
            "
            name="trash"
            @click="openArchiveDialog(user)"
          />
        </td>
      </template>
    </DataTable>

    <ActivateUserDialog
      v-model="activateDialog"
      :user="selectedUser"
      @activate="activateUser"
    />
    <DeactivateUserDialog
      v-model="deactivateDialog"
      :user="selectedUser"
      @deactivate="deactivateUser"
    />
    <EditUserDialog
      v-model="editDialog"
      :user="selectedUser"
      @finish="editUser"
    />
    <ArchiveUserDialog
      v-model="archiveDialog"
      :user="selectedUser"
      @archive="archiveUser"
    />
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { DataTable } from '@/components/dataTable'
import Pill from '@/components/Pill'
import Icon from '@/components/Icon'
import {
  ActivateUserDialog,
  DeactivateUserDialog,
  ArchiveUserDialog,
  EditUserDialog,
} from '@/components/dialog'
import { user as userHelper, general } from '@/helpers'
import { EUserRole, EUserStatus } from '@/enums'
import { useListFetch } from '@/composition'

export default {
  components: {
    DataTable,
    Pill,
    Icon,
    ActivateUserDialog,
    DeactivateUserDialog,
    EditUserDialog,
    ArchiveUserDialog,
  },
  setup() {
    const store = useStore()
    const errorMessage = () =>
      store.dispatch('toastMessage/showMessage', {
        type: 'error',
        translationKey: 'users-list-fetch-error',
      })
    return useListFetch('users', errorMessage)
  },
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    pageSlice: {
      type: Array,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
  },
  emits: ['update:table'],
  data() {
    return {
      activeFilter: 'all',
      activateDialog: false,
      deactivateDialog: false,
      editDialog: false,
      archiveDialog: false,
      selectedUser: null,
    }
  },
  inject: ['newUser'],
  computed: {
    users() {
      return this.list.map(user => ({
        ...user,
        roleName: userHelper.roleName(user.userRoles[0]),
      }))
    },
    activeUsers() {
      return this.users.filter(u => u.status === EUserStatus.Active)
    },
    inactiveUsers() {
      return this.users.filter(u => u.status === EUserStatus.Inactive)
    },
    pendingUsers() {
      return this.users.filter(u => u.status === EUserStatus.Pending)
    },
    filteredUsers() {
      switch (this.activeFilter) {
        case 'active':
          return this.activeUsers
        case 'locked':
          return this.inactiveUsers
        case 'pending':
          return this.pendingUsers
        default:
          return this.users
      }
    },
    searchTerm() {
      return this.search.trim().toLowerCase()
    },
    searchedFilteredUsers() {
      const searchKeys = ['firstName', 'lastName', 'organisationName']
      return general.searchOverKeys(
        this.filteredUsers,
        searchKeys,
        this.searchTerm
      )
    },
    usersPage() {
      return this.searchedFilteredUsers.slice(...this.pageSlice)
    },
    filters() {
      return [
        {
          key: 'all',
          item: {
            label: this.$t('accountManagement.all'),
            active: this.activeFilter === 'all',
            number: this.users.length,
          },
        },
        {
          key: 'active',
          item: {
            label: this.$t('accountManagement.active'),
            active: this.activeFilter === 'active',
            number: this.activeUsers.length,
          },
        },
        {
          key: 'locked',
          item: {
            label: this.$t('accountManagement.locked'),
            active: this.activeFilter === 'locked',
            number: this.inactiveUsers.length,
          },
        },
        {
          key: 'pending',
          item: {
            label: this.$t('accountManagement.pending'),
            active: this.activeFilter === 'pending',
            number: this.pendingUsers.length,
          },
        },
      ]
    },
    header() {
      return ['name', 'organisation', 'role', 'status', 'actions'].map(key => ({
        key,
        item: this.$t(`accountManagement.users-${key}`),
      }))
    },
  },
  methods: {
    isActive(user) {
      return user.status === EUserStatus.Active
    },
    onFilterSelect(key) {
      this.activeFilter = key
    },
    openStatusDialog(user) {
      this.selectedUser = user
      if (user.status === EUserStatus.Active) {
        this.deactivateDialog = true
      } else {
        this.activateDialog = true
      }
    },
    openEditDialog(user) {
      this.selectedUser = user
      this.editDialog = true
    },
    openArchiveDialog(user) {
      this.selectedUser = user
      this.archiveDialog = true
    },
    activateUser(user) {
      this.list.find(u => u.id === user.id).status = EUserStatus.Active
    },
    deactivateUser(user) {
      this.list.find(u => u.id === user.id).status = EUserStatus.Inactive
    },
    editUser(user) {
      const idx = this.list.findIndex(u => u.id === user.id)
      this.list[idx] = user
    },
    archiveUser(user) {
      this.list = this.list.filter(u => u.id !== user.id)
    },
  },
  watch: {
    'searchedFilteredUsers'(newList) {
      this.$emit('update:table', newList.length)
    },
    'newUser.value'(user) {
      this.list.push(user)
    },
  },
  created() {
    // no need to be reactive, so set here
    this.canChangeStatus = userHelper.hasPermission([
      EUserRole.AccountAdministrator,
      EUserRole.ScoringModelManager,
      EUserRole.AccountManager,
      EUserRole.ProspectiveBuyer,
    ])
  },
}
</script>
